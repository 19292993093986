import { replaceDoubleQuotes } from "../../../helpers/replaceDoubleQuotes";
import { TransistorEpisodeAttributes } from "../../../interfaces/podcasts";

interface EpisodeSchemaArgs {
  attributes: TransistorEpisodeAttributes;
  url: string;
  siteUrl: string;
}

export const createEpisodeSchema = ({
  attributes,
  url,
  siteUrl
}: EpisodeSchemaArgs) => `{
       "@context": "https://schema.org/",
       "@type": "PodcastEpisode",
       "url": "${url}",
       "name": "${attributes.title}",
       "datePublished": "${attributes.published_at}",
       "description": "${replaceDoubleQuotes(attributes.description)}",
       "associatedMedia": {
         "@type": "MediaObject",
         "contentUrl": "${attributes.media_url}"
       },
       "partOfSeries": {
         "@type": "PodcastSeries",
         "name": "Why Mums Don't Jump",
         "url": "${siteUrl}"
       }
    }`;
