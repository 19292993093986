import React from "react";

export interface GraphQLError {
  message: string;
  code?: string;
}

interface GraphQLErrorListProps {
  errors: Array<GraphQLError>;
}

const GraphQLErrorList: React.FC<GraphQLErrorListProps> = ({ errors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map(error => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
);

export default GraphQLErrorList;
